import React, { useContext, useState } from 'react';
import parse from 'html-react-parser';
import { produce } from 'immer';
import { Button, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Card } from '@mui/material';
import { CardActions } from '@mui/material';
import { CardContent } from '@mui/material';
import { Grid } from '@mui/material';
import { Typography } from '@mui/material';
import currency from 'currency.js';

import { AppContext } from '../context/AppContext';

const styles = {
  card: {
    padding: (theme) => theme.spacing(2),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: (theme) => theme.palette.primary.main,
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: '4px',
    marginTop: '12px',
    marginBottom: '12px',
  },
  cardContent: {
    flexGrow: 1,
  },
  cardActions: {
    justifyContent: 'center',
    flexDirection: 'column',
  },
  title: {
    textAlign: 'center',
  },
  selectedTitle: {
    color: (theme) => theme.palette.secondary.main
  },
  grid: {
    paddingBottom: (theme) => theme.spacing(2),
  },
  productGridItem: {
      flexGrow: 1,
      flexBasis: '50%',
  },
  buttonGroup: {
    marginTop: '1rem',
  }
}

const hasLineItem = (ord, prod) => { return ord.lineItems?.nodes?.find( lineItem => lineItem.product.node.name.includes(prod) ) }

const ProductCards = ( { order, setOrder, productType, products, warranties } ) => {
    const [ selectedWarranty, setSelectedWarranty ] = useState('none');
    const t3 = products.find( prod => prod.sku === '100-0007' )
    const t3_accessories = products.find( prod => prod.sku === 'smart-accessories' )
    const foundTonalLineItem = hasLineItem(order, productType)

    const removeWarranty = () => {
        setOrder( 
            produce( ( draftOrder ) => {
                const nodes = draftOrder.lineItems.nodes
                draftOrder.lineItems.nodes = nodes.filter( node => node.product.node.name.includes('warranty') === false )
            } )
        )
     }

     const handleClickAddLineItem = ( productId, findTonal = true, addAccessories = false ) => {
        if ( productId === null ) {
            return
        }

        if (productId === 'none') {
            removeWarranty()
            setSelectedWarranty(productId)
            return;
        }  

        let product = null
        if (findTonal) { 
            product = t3
        } else {
            removeWarranty()
            setSelectedWarranty(productId)
            product = warranties.find( warranty => warranty.databaseId == productId );
        }
        
        setOrder(
            produce( ( draftOrder ) => {
                const lineItems = draftOrder.lineItems
                lineItems.nodes.push( {
                    quantity: 1,
                    product: {
                        node: {
                            name: product.name,
                            databaseId: product.databaseId,
                            price: product.price,
                            image: product.image,
                        }
                    }
                } )
                if ( addAccessories ) {
                    lineItems.nodes.push( {
                        quantity: 1,
                        product: {
                            node: {
                                name: t3_accessories.name,
                                databaseId: t3_accessories.databaseId,
                                price: t3_accessories.price,
                                image: t3_accessories.image,
                            }
                        }
                    } )        
                }
            } )
        )  
     }
     
    const selectedProduct = () => {
        const nodes = order.lineItems.nodes?.filter(node => !node.product.node.name?.includes('warranty'))
        return nodes.length
    }

    return (
        <>
            {
                foundTonalLineItem && order.databaseId ? 
                    <Grid item sx={ { ...styles.productGridItem } }>
                        <Card sx={ { ...styles.card } }>
                            <CardContent>
                                <Typography gutterBottom sx={ { ...styles.title } } variant="h6" component="h1">
                                    {        
                                        (hasLineItem(order, t3_accessories.name)) ?
                                            `${ parse(t3.name) } with  ${ parse(t3_accessories.name) }`
                                        :
                                            `${ parse(t3.name) }`
                                    }
                                </Typography>
                                <Typography gutterBottom sx={ { ...styles.title } } variant="h6" component="h2">
                                    {        
                                        (hasLineItem(order, t3_accessories.name)) ?
                                            currency(t3.price).add(t3_accessories.price).format()
                                        :
                                            currency(t3.price).format()
                                    }
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                :
                    <>
                        <Grid key={ t3.databaseId } item xs={ 12 } sm={ 6 }>
                            <Card sx={ { ...styles.card } }>
                                <CardContent sx={ (selectedProduct() == 1) ? { ...styles.selectedTitle } : {} }>
                                    <Typography gutterBottom sx={ { ...styles.title } } variant="h6" component="h1">
                                        { parse(t3.name) }
                                    </Typography>
                                    <Typography gutterBottom sx={ { ...styles.title } } variant="h6" component="h2">
                                        { t3.price }
                                    </Typography>
                                    <CardActions sx={ { ...styles.cardActions } }>
                                        <Button disabled={ ( selectedProduct() > 0 ) } onClick={ () => { handleClickAddLineItem( t3.databaseId ) } } variant="outlined" color="secondary">Add</Button>
                                        {
                                            (selectedProduct() == 1) ?
                                                <ToggleButtonGroup sx={ { ...styles.buttonGroup } } orientation='vertical' aria-label='vertical button group' exclusive value={ selectedWarranty } onChange={ (event, newValue) => { handleClickAddLineItem(newValue, false) } }>
                                                    <ToggleButton value="none" variant="outlined" color="secondary">No Extended Warranty</ToggleButton>
                                                    {
                                                        warranties.map( warrant => {
                                                            return(
                                                                <ToggleButton key={ warrant.databaseId } value={ warrant.databaseId } variant="outlined" color="secondary">Add {warrant.name} {warrant.price}</ToggleButton>
                                                            )
                                                        })
                                                    }
                                                </ToggleButtonGroup>
                                            : ''
                                        }
                                    </CardActions>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid key={ t3_accessories.databaseId } item xs={ 12 } sm={ 6 }>
                            <Card sx={ { ...styles.card } }>
                                <CardContent sx={ (selectedProduct() === 2) ? { ...styles.selectedTitle } : {} }>
                                    <Typography gutterBottom sx={ { ...styles.title } } variant="h6" component="h1">
                                        { `${ parse(t3.name) } with  ${ parse(t3_accessories.name) }` }
                                    </Typography>
                                    <Typography gutterBottom sx={ { ...styles.title } } variant="h6" component="h2">
                                        { currency(t3.price).add(t3_accessories.price).format() }
                                    </Typography>
                                    <CardActions sx={ { ...styles.cardActions } }>
                                        <Button disabled={ (selectedProduct() > 0) } onClick={ () => { handleClickAddLineItem( t3.databaseId, true, true ) } } variant="outlined" color="secondary">Add</Button>
                                        {
                                            (selectedProduct() === 2) ?
                                                <ToggleButtonGroup sx={ { ...styles.buttonGroup } } orientation='vertical' aria-label='vertical button group' exclusive value={ selectedWarranty } onChange={ (event, newValue) => { handleClickAddLineItem(newValue, false) } }>
                                                    <ToggleButton value="none" variant="outlined" color="secondary">No Extended Warranty</ToggleButton>
                                                    {
                                                        warranties.map( warrant => {
                                                            return(
                                                                <ToggleButton key={ warrant.databaseId } value={ warrant.databaseId } variant="outlined" color="secondary">Add {warrant.name} {warrant.price}</ToggleButton>
                                                            )
                                                        })
                                                    }
                                                </ToggleButtonGroup>
                                            : ''
                                        }
                                    </CardActions>
                                </CardContent>
                            </Card>
                        </Grid>
                    </>
            }      
        </>
    )
}

const ProductSelect = () => {
  const { products, order, setOrder } = useContext(AppContext);

  return (
      <>
          {
                products ?
                    <Grid sx={ { ...styles.grid } } container spacing={ 2 }>
                        <ProductCards order={ order } products={ [ products.t3, products.t3_accessories ] } productType="Smart Accessories" setOrder={ setOrder } warranties={ products.warranties.products } />  
                    </Grid>
                : ''
          }
      </>
    )
}

export default ProductSelect;
